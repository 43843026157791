.button#whatsapp{
    border-radius: 30px;
    background: rgb(60, 162, 118) !important;
}
.button#condiciones{
    border-radius: 30px;
    background: rgb(43, 80, 168) !important;
}

/* .particles-bg-canvas-self{
    background: #c4cb8c;
} */

#mapa{
    width: 100% ;
    height: 25rem;
}
.leaflet-container.leaflet-touch.leaflet-retina.leaflet-fade-anim{
    width: 600px;
    height: 300px !important;
}
.item-wrap > img{
    cursor: pointer !important;
}

.leaflet-container{
    height: inherit !important;
    width: inherit !important;
}

#mapa iframe{
    width: 100%;
    height: 100%;
    border: 0;
}
